import * as complexConstants from '@/utils/analytics/constants/index'
import * as homeConstants from '@/utils/analytics/constants/home/constants'
import * as constants from '@/utils/analytics/constants/constants'

export const buildCustomNameRoute = (routeName = '') => (
  homeConstants.OPEN_SPEC_PAGE_ON_CLICK.replace(constants.ROUTE_NAME, routeName)
)

export default function getScreenChanged(routeName = '') {
  complexConstants.START_FLOW_VIA_HOMEPAGE.attributes.info = buildCustomNameRoute(routeName)
  return complexConstants.START_FLOW_VIA_HOMEPAGE
}
